import React from 'react';
import { PageLayout } from '../components/pageLayout';
import { Flex, Heading, Text, Box } from '@chakra-ui/core';

interface Props {}

const errorPage = (props: Props) => {
	return (
		<PageLayout>
			<Flex
				justifyContent="center"
				textAlign="center"
				alignItems="center"
				height="400px"
			>
				<Box>
					<Heading fontSize="6xl">404</Heading>
					<Text>
						Click{' '}
						<a href="/">
							<strong>here</strong>
						</a>{' '}
						to go home
					</Text>
				</Box>
			</Flex>
		</PageLayout>
	);
};

export default errorPage;
